<script>
import $ from "jquery";
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

// import Swal from "sweetalert2";
// import axios from "axios";
import Swal from "sweetalert2";
import axios from "axios";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Edit Konfigurasi Ekstensi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Konfigurasi Ekstensi",
      items: [
        {
          text: "Master",
          href: "/",
        },
        {
          text: "Konfigurasi Ekstensi",
          href: "/master/Konfigurasi Ekstensi",
        },
        {
          text: "Edit Konfigurasi Ekstensi",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      // variable Page

      id: this.$route.params.id,
      status: "",
      menu_selected: "",
      nama_extension: "",
      value_extension: [],
      max_upload_size: "",
      menu: [
        {
            value: "Arsip Dokumen",
            label: "arsip_dokumen",
        },
        {
            value: "Digitalisasi",
            label: "digitalisasi",
        },
        {
            value: "Monitoring",
            label: "monitoring",
        },
      ],
      tipe_ekstensi: [
        {
            value: "all",
            label: "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.documen, application/vnd.ms-powerpoint, image/jpeg, image/png",
        },
        {
            value: ".pdf",
            label: "application/pdf",
        },
        {
            value: ".xlsx",
            label: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
        },
        {
            value: ".docx",
            label: "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        },
        {
            value: ".pptx",
            label: "application/vnd.ms-powerpoint",
        },
        {
            value: ".jpg, .png",
            label: "image/jpeg, image/png",
        }
      ],
    };
  },
  mounted() {
    this.getKonfigurasiEkstensi();
  },
  methods: {
    getKonfigurasiEkstensi() {
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });

      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "/api/master/konfigurasi_ekstensi?id=" +
            self.id
        )
        .then((response) => {
          var response_data = response.data;
          var response_data_fix = response_data.list_data.data[0];
          if (response_data.code == 200) {
            self.max_upload_size = response_data_fix.max_upload_size;
            self.status = response_data_fix.status;

              var json_menu = self.menu;
              let clear_json_menu = [];
              $.each(json_menu, function (indexInArray, valueOfElement) { 
                if(response_data_fix.menu == valueOfElement.label){
                  clear_json_menu.push({
                  'value': valueOfElement.value,
                  'label': valueOfElement.label,
                })
                }
              });
              self.menu_selected = clear_json_menu[0];

            // VALUE EXTENSTION
            if(response_data_fix.value_extension){
              var json_response_value_extension = JSON.parse(response_data_fix.value_extension);
              let clear_data_json_response_value_extension = [];
              $.each(json_response_value_extension, function (indexInArray, valueOfElement) { 
                clear_data_json_response_value_extension.push({
                  'value': valueOfElement.value,
                  'label': valueOfElement.label,
                })
              });
              self.value_extension = clear_data_json_response_value_extension;
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
          Swal.close();
        });
      // this.getKonfigurasiEkstensiRole();
    },

    StoreData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });
      var FormData = require("form-data");
      var data = new FormData();
      data.append("menu", self.menu_selected?.label);
      data.append("value_extension", JSON.stringify(self.value_extension));
      data.append("max_upload_size", self.max_upload_size);
      data.append("id", self.id);

      var config = {
        method: "post",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/konfigurasi_ekstensi/update",
        data: data,
      };
      axios(config)
        .then(function (response) {
          var response_data = response.data; 
          if(response_data.code != 200){
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: response_data.message,
            });
          }else{
            let timerInterval;
            Swal.fire({
              icon: "success",
              title: "Berhasil",
              text: "Anda akan diarahkan ke halaman master konfigurasi ekstensi segera",
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
                self.$router.push({ name: "all-konfigurasi_ekstensi" });
              }
            });
          }
        })
        .catch((e) => {
          this.axiosCatchError = e.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                  >
                  <label>Menu <span class="text-danger">* Wajib diisi</span></label>
                    <v-select
                      v-model="menu_selected"
                      :options="menu"
                      label="value"
                    ></v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                  >
                  <label>Pilih Format Ekstensi <span class="text-danger">* Wajib diisi</span></label>
                    <v-select
                      v-model="value_extension"
                      :options="tipe_ekstensi"
                      label="value"
                      multiple
                    ></v-select>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group
                    class="mb-3"
                  >
                  <label>Ukuran maksimal file <span class="text-danger">* Wajib diisi</span></label>
                    <b-form-input
                      type="number"
                      v-model="max_upload_size"
                    ></b-form-input>
                  <small>Masukkan nilai dalam bentuk Byte</small>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-button type="submit" variant="primary" class="m-1"
                ><i class="fa fa-save"></i> Simpan</b-button
              >
              <b-button type="reset" variant="danger" class="m-1"
                ><i class="fa fa-refresh"></i> Reset</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
